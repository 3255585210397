import { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import axios from "axios";
import "../Styles/main.css";
import "../Styles/index.css";
import "../Styles/events.css";
import Nav from "../components/Nav";
import Logo from "../Images/bonsaiSocIcon.png";

const SPREADSHEET_ID = "1LvC4ver-fd1KAPo9OxSYhVKXQlQYIw1u2p68PzjK3PY";
const API_KEY = process.env.REACT_APP_API_KEY;

function Events() {
  const [data, setData] = useState([]);

  const handleScroll = () => {
    const scrollPosition = window.scrollY || document.documentElement.scrollTop;
    const blurAmount = Math.min(scrollPosition / 1.4, 50);

    const blurElement = document.getElementById("blur-overlay");
    if (blurElement) {
      blurElement.style.backdropFilter = `blur(${blurAmount}px)`;
      blurElement.style.webkitBackdropFilter = `blur(${blurAmount}px)`; // For Safari
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `https://sheets.googleapis.com/v4/spreadsheets/${SPREADSHEET_ID}/values/Sheet1?key=${API_KEY}`
        );
        const rows = response.data.values;
        setData(rows.slice(1)); // Ignore the first row
      } catch (error) {
        console.error("Error fetching data: ", error);
      }
    };

    fetchData();
  }, []);

  return (
    <>
      <Helmet>
        <title>Southend Bonsai Society | Events</title>
        <meta
          name="description"
          content="Check out the upcoming events and activities of the Southend Bonsai Society."
        />
        <meta
          name="keywords"
          content="Southend Bonsai Society, bonsai events, bonsai activities"
        />
        <meta property="og:title" content="Southend Bonsai Society | Events" />
        <meta
          property="og:description"
          content="Check out the upcoming events and activities of the Southend Bonsai Society."
        />
      </Helmet>
      <link rel="stylesheet" href="/public/Styles/main.css" />
      <link rel="stylesheet" href="/public/Styles/index.css" />
      <link rel="icon" href="/public/Images/bonsaiSocIcon.ico" />
      <Nav />
      <div id="content-wrapper">
        <div id="blur-overlay">
          <div id="main">
            <div id="center">
              <div className="content-container">
                <h1>Event Schedule</h1>
                <img className="icon" alt="Icon" src={Logo} />
                <div>
                  {data.map((row, index) => (
                    <div className="event" key={index}>
                      <h2>{row[0]}</h2>
                      <p>{row[1]}</p>
                      <p>{row[2]}</p>
                      <p>{row[3]}</p>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Events;
