import { Fragment, useEffect } from "react";
import { Helmet } from "react-helmet";
import "../Styles/main.css";
import "../Styles/index.css";
import Nav from "../components/Nav";
import Logo from "../Images/bonsaiSocIcon.png";
function About() {
  const handleScroll = () => {
    const scrollPosition = window.scrollY || document.documentElement.scrollTop;
    const blurAmount = Math.min(scrollPosition / 1.4, 50);

    const blurElement = document.getElementById("blur-overlay");
    if (blurElement) {
      blurElement.style.backdropFilter = `blur(${blurAmount}px)`;
      blurElement.style.webkitBackdropFilter = `blur(${blurAmount}px)`; // For Safari
    }
  };

  useEffect(() => {
    // Dynamically import Tailwind CSS
    import("tailwindcss/tailwind.css");
  }, []);

  const events = [
    {
      heading: "1979",
      subHeading:
        "Len Howard, a member of the National Bonsai Society meetings were held on the third Thursday of each month with low attendance at St Clement's Hall, Leigh-on-Sea. The year after it did generate a lot of public interest and moved to the Royal Naval Association Hall.",
      direction: "left",
    },
    {
      heading: "1982 & 1983",
      subHeading:
        "The venue once again changed to St. Mary's Hall. The first garden open day was held.",
      direction: "right",
    },
    {
      heading: "1983",
      subHeading:
        "Len Hayward and Ken Taperell expressed a desire to become less involved with the society to devote more time to talk and demonstrations.",
      direction: "left",
    },
    {
      heading: "1984",
      subHeading:
        "A new committee was elected at the Annual General Meeting with the chairman being Jim Sanctuary, secretary being John Wilding and treasurer Alan Hooper who used his technical and carpentry to improve the quality of meetings. The logo was officially made during a competition by Pam Wilding. Advertisement campaigns started by having posters in garden centres, libraries and horticultural societies across town.",
      direction: "right",
    },
    {
      heading: "1985",
      subHeading:
        "The club officially became known as 'Southend Bonsai Society'. Len Hayward passed away following a long illness. The Society's funds increased allowing guest speakers and artists to be invited to events including Peter Chan, Peter Adams, Bill Jordan and Harry Tominson.",
      direction: "left",
    },
    {
      heading: "1989",
      subHeading:
        "To celebrate the 10th anniversary, a major national exhibition of bonsai was staged at Cliffs Pavilion entitled 'Bonsai 89'. Over 2,500 people were in attendance and much is owed to Ron Willson whose fund-raising contributed to the success of the show.",
      direction: "right",
    },
    {
      heading: "1992",
      subHeading:
        "Southend Bonsai hosted and staged the first ever national autumn exhibition of bonsai held in GB with the theme 'The Autumn Shades of Bonsai' held at the Palace Hotel being another successful event.",
      direction: "left",
    },
    {
      heading: "2001",
      subHeading:
        "We once again relocated to Bushukan Bonsai Nursery in Hockley",
      direction: "right",
    },
    {
      heading: "2007",
      subHeading:
        "The club continued to grow now having more guest speakers, demonstrations and workshops. Trips to shows in the UK, EU and Japan were organised with the society.",
      direction: "left",
    },
    {
      heading: "2017",
      subHeading:
        "We hit a record of shows exhibited at the Spring Plant Fair, The Summer Show and The Celebration of Trees. \n Guest speakers that year included Simon Tremblett, Ramon Hamers, Steve McKee and Peter Sandford.",
      direction: "right",
    },
    {
      heading: "2018",
      subHeading: "Displays at Hyde Hall, Hullbridge and the Museum of Power",
      direction: "left",
    },
    {
      heading: "2019",
      subHeading:
        "40th Anniversary of the society! We once again held shows at Hyde Hall and Hullbridge and Museum of Power. We also had our first appearance at the East Anglia Bonsai Show in Ipswich.",
      direction: "right",
    },
    {
      heading: "2021",
      subHeading:
        "Despite the pandemic we found a much larger hall for our meetings and club events when restrictions started being eased in which we moved to Ashingdon and Hawkwell Memorial Hall. We also managed to organise displays at Hyde Hall, East Anglia and our own club show in Rochford.",
      direction: "left",
    },
    {
      heading: "2022",
      subHeading:
        "We had our most successful event to date in which the society had achieved a gold medal at the RHS Flower Show in Chelmsford resulting in a £300 award to the club. We had displays at Hyde Hall, Ashingdon, Kesgrave and Heathrow. \n In Kesgrave we were awarded 'Best Club Display' thanks to the help of members who provided trees for the display.\n We finally got a Facebook page and WhatsApp chat to help put us in the public domain and ensure more ease for promoting activities and events.",
      direction: "right",
    },
    {
      heading: "2023",
      subHeading:
        "We retained our 'Best Club Display' award from last year at the East of England Show in Kesgrave and was awarded joint 3rd place for the 'Public Vote for Best Club Display' at Heathrow.",
      direction: "left",
    },
  ];

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const Timeline = ({ events }) => {
    return (
      <div className="flex flex-col gap-y-3 w-full my-4">
        <Circle />

        {events.map((event, key) => {
          return (
            <Fragment key={key}>
              <div className="grid grid-cols-[1fr_auto_1fr] gap-x-2 items-center mx-auto">
                {event.direction === "left" ? (
                  <EventCard
                    heading={event.heading}
                    subHeading={event.subHeading}
                  />
                ) : (
                  <div></div>
                )}

                <Pillar />

                {event.direction === "right" ? (
                  <EventCard
                    heading={event.heading}
                    subHeading={event.subHeading}
                  />
                ) : (
                  <div></div>
                )}
              </div>
              {key < events.length - 1 && <Circle />}
            </Fragment>
          );
        })}

        <Circle />
      </div>
    );
  };

  const Circle = () => {
    return <div className="rounded-full w-4 h-4 bg-green-500 mx-auto"></div>;
  };

  const Pillar = () => {
    return (
      <div className="rounded-t-full rounded-b-full w-2 h-full bg-green-500 mx-auto"></div>
    );
  };

  const EventCard = ({ heading, subHeading }) => {
    return (
      <div className="flex flex-col bg-white gap-y-2 border shadow-md rounded-xl p-4">
        <div className="font-bold text-lg border-b">{heading}</div>
        <div className="text-base text-gray-700">{subHeading}</div>
      </div>
    );
  };

  return (
    <>
      <Helmet>
        <title>Southend Bonsai Society | About Us</title>
        <meta
          name="description"
          content="Learn more about the Southend Bonsai Society, its history, and mission."
        />
        <meta
          name="keywords"
          content="Southend Bonsai Society, bonsai history, bonsai mission"
        />
        <meta
          property="og:title"
          content="Southend Bonsai Society | About Us"
        />
        <meta
          property="og:description"
          content="Learn more about the Southend Bonsai Society, its history, and mission."
        />
      </Helmet>
      <link rel="stylesheet" href="/public/Styles/main.css" />
      <link rel="stylesheet" href="/public/Styles/index.css" />
      <link rel="icon" href="/public/Images/bonsaiSocIcon.ico" />
      <Nav />
      <title>Southed Bonsai - Our History</title>
      <div
        id="content-wrapper"
        className="bg-custom-bg bg-no-repeat bg-center bg-fixed bg-cover"
      >
        <div id="blur-overlay">
          <div id="main">
            <div id="center">
              <div className="content-container">
                <h1 className="text-4em font-700">Our History</h1>
                <img className="icon" alt="Icon" src={Logo} />
                <Timeline events={events} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default About;
